$(function(){

		/**
		 * Update school type from dropdown
		 */
		$('body').on('click', '.updateDropdownToggleSchoolType a',function(e){
			e.preventDefault();
			let currentSchoolType = $(this);
			currentSchoolType.addClass('active').siblings().removeClass('active');
			currentSchoolType.parent().prev().text(currentSchoolType.text());
		});

		//=========================== Location Autocomplete===================================

		/*============================ common request ==================================*/
		// sessionStorage.removeItem('addBoardingLocation');
		/*============================ /common request ==================================*/

		/*----------------- for header search ---------------------*/
		let headerSearchLocationInput = document.getElementById('headerSearchLocationInput');
		let headerSearchLocationAutoComplete, headerSearchLocationPlaces = '';
		if(!!headerSearchLocationInput){
				headerSearchLocationAutoComplete = new CustomAutoCompleteMaps(headerSearchLocationInput);
				headerSearchLocationPlaces = '';
				if(!!headerSearchLocationAutoComplete){
						CustomAutoCompleteMaps.addListener(headerSearchLocationAutoComplete, 'place_changed', () =>{
								headerSearchLocationPlaces = headerSearchLocationAutoComplete.getPlace();
						});
				}		
		}
		/*----------------- /for header search ---------------------*/


		//=========================== /Location Autocomplete==================================

		//=========================== Location Autocomplete Submit===================================

		$('#headerSearchLocationSubmit').on('click', function(e){
				e.preventDefault();
				e.stopPropagation();
				/*	school type slider field	*/
				let school_type = $('#headerSchoolType a.active').data('school-type');
				let school_type_text = getSchoolTypeSearchText(school_type);
				localStorage.setItem('searched_school_type', school_type);
				sessionStorage.removeItem('homepageSearchSession');

				sessionStorage.setItem('addBoardingLocation', '1');


				if (school_type === 6) {
						window.location.href = `${HOST}/search/${school_type_text}/?`;
						return false;
				}

				if (school_type_text === undefined) {
						launchErrorToast('Please Select School Type');
						return false;
				}

				let place_name = headerSearchLocationInput.value;
				let city2 = '';
				if (headerSearchLocationPlaces !== "") city2 = headerSearchLocationPlaces.name;

				if ((place_name === '' && city2 === undefined) || headerSearchLocationPlaces === '') {
						launchErrorToast('Please type location');
						return false;
				}

				/*	analytics header search	*/
				HeaderSearchAnalytics(school_type_text);

				let place_level = headerSearchLocationPlaces.address_components[0].types[0];
				let place_lat = headerSearchLocationPlaces.geometry.location.lat();
				let place_lng = headerSearchLocationPlaces.geometry.location.lng();

				let filteredFormattedAddressData = filterGoogleMapApiResponse(headerSearchLocationPlaces);
				let filteredFormattedAddressDataStr = filteredFormattedAddressDataSlug(headerSearchLocationPlaces);

				let placeLevel = '';
				let localityObj = headerSearchLocationPlaces.address_components.filter((v, i) => {
						if(v.types[0] === 'locality') return v;
				});

				let administrativeAreaLevel1 = headerSearchLocationPlaces.address_components.filter((v, i) => {
						// console.log(v.types[0]);
						if(v.types[0] === 'administrative_area_level_1') return v;
				});

				let administrativeAreaLevel2 = headerSearchLocationPlaces.address_components.filter((v, i) => {
						// console.log(v.types[0]);
						if(v.types[0] === 'administrative_area_level_2') return v;
				});

				let formatted_address = headerSearchLocationPlaces.formatted_address;
				formatted_address = slugify(formatted_address);

				/*	Insert Search History Code	*/

				let searchPageName = (typeof (pageType) != "undefined") ? pageType : window.location.href;

				let SearchText = `${school_type_text} in ${formatted_address}`;
				let cityText = '';

				if(localityObj.length) {
						// cityText = localityObj[0].long_name.toLowerCase();
						cityText = filteredFormattedAddressData.city.toLowerCase();
				}


				let locality = '';
				if(filteredFormattedAddressData.hasOwnProperty('locality')){
						if(!!filteredFormattedAddressData.locality && filteredFormattedAddressData.locality.length){
								placeLevel = localityObj[0].types[0];
								locality = filteredFormattedAddressData.locality[filteredFormattedAddressData.locality.length - 1];
						}
				}

				let city_2_name = '';
				if(administrativeAreaLevel2.length){
						city_2_name = slugify(administrativeAreaLevel2[0].long_name);
						// if(!placeLevel) placeLevel = administrativeAreaLevel2[0].types[0];
				}

				let state_name = "";
				if(!!administrativeAreaLevel1.length){
						// state_name = slugify(administrativeAreaLevel1[0].long_name);
						state_name = slugify(filteredFormattedAddressData.state);
						if(!placeLevel && !cityText) placeLevel = administrativeAreaLevel1[0].types[0];
				}

				/* check if city name like state name */
				if(!!cityText){
						let checkCityAsStateExist = checkCityExistsInStates(cityText);
						if(checkCityAsStateExist.length){
								cityText = '';
								city_2_name = '';
								if(!!administrativeAreaLevel1.length) placeLevel = administrativeAreaLevel1[0].types[0];
						}
				}

				if(pageType !== "SEO"){
						city_2_name = '';
				}

				let searchSessionData = {
						'locality' : localityObj,
						'administrativeAreaLevel1' : administrativeAreaLevel1,
						'administrativeAreaLevel2' : administrativeAreaLevel2,
						'city_name' : cityText,
						'city_2_name' : city_2_name,
						'state_name' : state_name,
						'formattedAddress': formatted_address,
						'latitude' : place_lat,
						'longitude': place_lng,
						'placeLevel': placeLevel,
						'searchPlaceLocationData': headerSearchLocationPlaces
				};

				localStorage.setItem('searchSessionData', JSON.stringify(searchSessionData));

				/*for (let i = 0; i < headerSearchLocationPlaces.address_components.length; i++) {
						const addr = headerSearchLocationPlaces.address_components[i];
						if (addr.types[0] === ['locality']) cityText = addr.long_name;	/!*	City	*!/
				}*/

				if(pageType !== "SEO"){
						city_2_name = '';
				}

				let InsertSearchParameter = {
						'page': searchPageName,
						'city': cityText,
						'filter': '',
						'filter_text': SearchText,
						'type_id': school_type,
						'location_short': city2,
						'location_long': formatted_address,
						'lat': place_lat,
						'long': place_lng,
				};

				InsertSearchApi(InsertSearchParameter);

				/* INSERT SEARCH HISTORY CODE END */

				let redirectUrl = '';
				// if(placeLevel.length) redirectUrl +=`place_lavel=${placeLevel}`;
				// if(cityText.length) redirectUrl += (placeLevel.length ? '&' : '') + `city_name=${slugify(cityText)}`;
				// if(locality.length) redirectUrl += (cityText.length ? '&' : '') + `locality=${slugify(locality)}`;
				// if((cityText !== city_2_name) && city_2_name.length) redirectUrl += (cityText.length ? '&' : '') + `city_2_name=${slugify(city_2_name)}`;
				// if((!cityText && !city_2_name) && state_name.length) redirectUrl += `&state_name=${slugify(state_name)}`;
				redirectUrl+=filteredFormattedAddressDataStr;



				if(placeLevel === 'locality' && (parseInt(school_type) === 3)){
						if(placeLevel.length) redirectUrl +=`&sort_by=distance`;
				}

				/**
				 * *********Range Max********
				 *  Day & PU :   30 in case City || 5  in case Locality
				 *  PreSchool : 10 in case City || 5  in case Locality
				 *  Boarding : 100 in case Locality || City Both
				 *  Start
				 * */

				let localityStr = getCleanLocality(formatted_address,cityText,city_2_name,state_name);

				let distanceRangeMax = '';
				if( [1, 5].includes(parseInt(school_type)) && localityStr){ // update range slider in case of city id
						distanceRangeMax = 5;
				}
				if(!!cityText.length && [1, 5].includes(parseInt(school_type)) && !localityStr){ // update range slider in case of city id
						distanceRangeMax = 30;
				}

				if( [2].includes(parseInt(school_type)) && localityStr){ // update range slider in case of city id
						distanceRangeMax = 5;
				}
				if(!!cityText.length && [2].includes(parseInt(school_type)) && !localityStr){ // update range slider in case of city id
						distanceRangeMax = 10;
				}

				if( [3].includes(parseInt(school_type)) && localityStr){ // update range slider in case of city id
						distanceRangeMax = 100;
				}
				if(!!cityText.length && [3].includes(parseInt(school_type))){ // update range slider in case of city id
						distanceRangeMax = 100;
				}

				if(distanceRangeMax) redirectUrl += `&range_max=${distanceRangeMax}`;
				/**
				 * *********Range Max*******
				 * END
				 **/

				$.ajax({
						url: `${HOST}/home/encode_decode.php`,
						data: {latitude: place_lat, longitude: place_lng},
						async: true,
						error: function () {
								alert("Something went wrong!");
						},
						success: function (response) {
								response = JSON.parse(response);
								window.location.href = `${HOST}/search/${school_type_text}/${formatted_address.toLowerCase()}-${response.data.latlng}${redirectUrl.length? '/?' + redirectUrl : ''}`;
						},
						type: 'GET'
				});
		});

		//=========================== /Location Autocomplete Submit==================================

		//=========================== Update header search inputs ==================================
		let searchedSchoolType = sessionStorage.getItem('searched_school_type')
		if(!!searchedSchoolType){
				$('.updateDropdownToggleSchoolType').find(`[data-school-type=${searchedSchoolType}]`).trigger('click');
		}
		//=========================== /Update header search inputs ==================================

		function getSchoolTypeSearchText($schoolType) {
				$schoolType = parseInt($schoolType);
				if ($schoolType === 1) return 'day-school';
				else if ($schoolType === 2) return 'pre-school';
				else if ($schoolType === 3) return 'boarding-school';
				else if ($schoolType === 5) return 'pu-junior-colleges';
				else if ($schoolType === 4) return 'day-care';
		}

		setSearchTypeByHistory();

		function setSearchTypeByHistory() {
				let searchedSchool = localStorage.getItem('searched_school_type');
				if (searchedSchool) $(".updateDropdownToggleSchoolType").find("[data-school-type='" + searchedSchool + "']").trigger('click');
		}

		/* Tooltip */
		initializeTooltip();



		
});

const showLoader = () => {
		$('#webLoader').removeClass('d-none');
};

const hideLoader = () => {
		$('#webLoader').addClass('d-none');
}


function getSchoolTypeName($schoolType) {
		$schoolType = parseInt($schoolType);

		switch ($schoolType) {
				case  1 :
						return 'day-school';
				case 2 :
						return 'pre-school';
				case 3 :
						return 'boarding-school';
				case 5:
						return 'pu-junior-colleges';
				case 4:
						return 'day-care';
				case 6:
						return 'online-school';
		}
}


function getBoardName(id) {

		let board = "";
		switch (id) {
				case "1":
						board = "cbse";
						break;
				case "2":
						board = "icse-isc";
						break;
				case "3":
						board = "ib";
						break;
				case "4":
						board = "others";
						break;
			case "5":
				board = "state";
				break;
				case "6":
						board = "igcse";
						break;
		}

		return board;
}

function getRegionName(id) {

		let region = "";
		switch (id) {
				case "1":
						region = "north";
						break;
				case "2":
						region = "east";
						break;
				case "3":
						region = "south";
						break;
				case "4":
						region = "west";
						break;
				case "5":
						region = "central";
						break;
		}

		return region;
}

function getCoedTypeName(id) {

		let coed = "";
		switch (id) {
				case "1":
						coed = "coed";
						break;
				case "2":
						coed = "girls";
						break;
				case "3":
						coed = "boys";
						break;
		}
		return coed;
}

function encode_decode(params = {}) {
		let formatObj;
		// console.log({params});
		if (params['latitude'] && params['latitude'] !== "" && params['longitude'] && params['longitude'] !== "") {
				let latLngStr = params['latitude'] + ',' + params['longitude'];
				formatObj = {data: {'latlng': window.btoa(latLngStr)}, 'error': ''};
		} else formatObj = {data: {}, error: 'Error!!'};

		return formatObj;
}


function initializeTooltip(){
		// update school name because capitalize not works in jquery
		$('[data-toggle="tooltip"]').tooltip({template: `<div class="tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner text-capitalize"></div></div>`});
}

function checkCityExistsInStates(cityName){
		return getAllStates.filter((stateObj, i) => {
				let stateName = stateObj.name.toLowerCase();
				if(stateName === cityName && cityName!== 'delhi') {
						return true;
				}
		});
}


function getParameterByName(name, url = window.location.href) {
		name = name.replace(/[\[\]]/g, '\\$&');
		let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
				results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function getCleanLocality(formatted_address,city,city2,state,country='india'){
		return formatted_address.replace(new RegExp(city2, "gi"), '')
				.replace(new RegExp(city, "gi"), '')
				.replace(new RegExp(state, "gi"), '')
				.replace(new RegExp(country, "gi"), '')
				.replace( new RegExp('-', "gi"), '')
				.replace( new RegExp(' ', "gi"), '')
				.replace( new RegExp(',', "gi"), '');
}


function textCapitalize(text){
		return  text.replace('-', ' ')
				.replace(/^(.)|\s(.)/g, function ($text) {
						return $text.toUpperCase()
				});
}

function scrollToTop(selector, offset){
		$('html, body').animate({scrollTop:$(selector).position().top - offset}, 'slow');
}

function scrollToDiv(id, offset = 0) {
	const element = document.getElementById(id);
	if (element) {
		const elementPosition = element.getBoundingClientRect().top + window.scrollY;
		const offsetPosition = elementPosition + offset;

		window.scrollTo({
			top: offsetPosition,
			behavior: 'smooth'
		});
	} else {
		console.error('Element with ID', id, 'not found.');
	}
}


